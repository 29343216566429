import React, { useState } from 'react';
import { FaMinus, FaPlus, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { rootUrl } from '../../../../helpers';
import axios from 'axios';

const InputForm = ({activityId, closeModal}) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        label: '',
        type: '',
        description: '',
        options: [{ code: '', optionType: '' }],
        required: '',
        skip: '',
        criteria: 'All',
      });
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      const handleOptionChange = (index, e) => {
        const { name, value } = e.target;
        const newOptions = formData.options.map((option, i) =>
          i === index ? { ...option, [name]: value } : option
        );
        setFormData((prevState) => ({
          ...prevState,
          options: newOptions,
        }));
      };

      const addOption = () => {
        setFormData((prevState) => ({
          ...prevState,
          options: [...prevState.options, { code: '', name: '' }],
        }));
      };
    
      const removeOption = (index) => {
        const newOptions = formData.options.filter((_, i) => i !== index);
        setFormData((prevState) => ({
          ...prevState,
          options: newOptions,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();    
        setLoading(true);
        try{
            const response = await axios.post(rootUrl(`/api/FamilyPlaning/Add-Service-Forms/${activityId}`), [formData])
                toast.success(response.data.message)
                closeModal()
                setLoading(false);
        }catch(e){
            toast.error(e?.message)
            setLoading(false);
        }
      };
    
      return (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-medium">Element ID</label>
              <input
                type="text"
                name="label"
                value={formData.label}
                onChange={handleChange}
                required
                className="mt-1 border p-2 focus:outline-none block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium">Type</label>
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
                className="mt-1 border p-2 focus:outline-none block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">select type</option>
                <option value="select">select</option>
                <option value="text">text</option>
                <option value="textarea">textarea</option>
                <option value="checkbox">checkbox</option>
                <option value="radio">radio</option>
                <option value="date">date</option>
                <option value="time">time</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-medium">Element Name</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                className="mt-1 block w-full border p-2 focus:outline-none border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium">Required</label>
              <div className="mt-1 flex space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="required"
                    value="Yes"
                    checked={formData.required === 'Yes'}
                    onChange={handleChange}
                    required
                    className="form-radio text-blue-600"
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="required"
                    value="Conditional"
                    checked={formData.required === 'Conditional'}
                    onChange={handleChange}
                    required
                    className="form-radio text-blue-600"
                  />
                  <span className="ml-2">Conditional</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="required"
                    value="Optional"
                    checked={formData.required === 'Optional'}
                    onChange={handleChange}
                    required
                    className="form-radio text-blue-600"
                  />
                  <span className="ml-2">Optional</span>
                </label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
          {formData.required === 'Conditional' && (
            <div>
              <label className="block text-gray-700 font-medium">Skip Logic</label>
              <input
                type="text"
                name="skip"
                value={formData.skip}
                onChange={handleChange}
                className="mt-1 block w-full border p-2 focus:outline-none border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          )}

            <div>
              <label className="block text-gray-700 font-medium">Criteria</label>
              <input
                type="text"
                name="criteria"
                value={formData.criteria}
                onChange={handleChange}
                required
                className="mt-1 block w-full border p-2 focus:outline-none border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            </div>
          <div>
            {
                formData.type === 'radio' || formData.type === 'checkbox' || formData.type === 'select'? (
                    <>
                    <label className="block text-gray-700 font-medium">Options</label>
            {formData.options.map((option, index) => (
              <div key={index} className="flex space-x-4 mt-2">
                <input
                  type="text"
                  name="code"
                  placeholder="Code"
                  value={option.code}
                  onChange={(e) => handleOptionChange(index, e)}
                  className="block w-1/2 border p-2 focus:outline-none border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                <input
                  type="text"
                  name="optionType"
                  placeholder="Name"
                  value={option.optionType}
                  onChange={(e) => handleOptionChange(index, e)}
                  className="block w-1/2 border p-2 focus:outline-none border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                  type="button"
                  onClick={() => removeOption(index)}
                  className="bg-red-500 text-white px-2 py-1 rounded-md"
                >
                  <FaMinus/>
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addOption}
              className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              <FaPlus/>
            </button>
            </>
                ):<></>
            }
            
          </div>
          <hr className='my-2' />
          <div className='flex justify-center'>
            <button
                disabled={loading}
                type="submit"
                className=" w-[25%] mt-4 w-full bg-green-500 text-white px-4 py-2 rounded-md"
            >
                {loading ? <FaSpinner/> : 'Submit'}
            </button>
          </div>
        </form>
      );
};
export default InputForm;
