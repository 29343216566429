import React, {useState, useRef, useEffect, useMemo } from 'react'
import {  FaInfo, FaInfoCircle, FaSpinner, FaThumbsUp, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { rootUrl } from '../../../helpers';
import axios from 'axios';
import { Dangerous, ResetTvRounded, Warning } from '@mui/icons-material';
import { FaCircleInfo } from 'react-icons/fa6';
import Select from 'react-select';


export default function InputForm({responses, data, user, closeModal, getResp, encounterId}) {
    
    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [dsActive, setDsActive] = useState(false);
    const [dsText, setDsText] = useState('');
    const [calculatedResults, setCalculatedResults] = useState({});

    const [modal, setModal] = useState({ visible: false, top: 0, left: 0 });
    const [currentField, setCurrentField] = useState('');
    const iconRefs = useRef([]);

    const closeModals = () => {
        setModal({ visible: false, top: 0, left: 0 });
    };


    const removeEmptyStrings = (arr) => {
        return arr.map(subArray => subArray.filter(item => item !== ""));
      };


      const handleMultiSelectChange = (selectedOptions, field) => {
        const value = Array.isArray(selectedOptions) 
            ? selectedOptions.map(option => option.value) 
            : selectedOptions ? selectedOptions.value : []; 
    
        handleChange({
            target: {
                name: field.name,
                value: value,
                dataset: { formId: field.formId }
            }
        });
    };
    
    const handleChange = async (e) => {
        
        const { type, name } = e.target;
        let value;

        if (type === 'checkbox') {
            const { checked, value: checkboxValue } = e.target;
            const currentValues = formValues[name] || [];
            if (checked) {
              value = [...currentValues, checkboxValue];
            } else {
              value = currentValues.filter(item => item !== checkboxValue);
            }
    }  else {
            value = e.target.value;
          }
        setFormValues({
            ...formValues,
            [name]: value,
        });

        if(type === 'number'){

                if(parseInt(value) > 20){
                    toast.error("Number too large");
                    setFormValues({
                        ...formValues,
                        [name]: '',
                    });
                    return
                }

                if(parseInt(value) < 0){
                    toast.error("You can not input number less less than 0");
                    setFormValues({
                        ...formValues,
                        [name]: '',
                    });
                    return
                }

        }

        if (type === 'date') {
            const inputDate = new Date(value);
            const today = new Date();
            const differenceInTime = today - inputDate;
            const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));


            if(differenceInDays > 180){
                toast.error("Date is too far behind");
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
                return
            }

            if(differenceInDays < 0){
                toast.error("You can not select a date in the future");
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
                return
            }

            if(name === 'FP.B.DE3332' || name === 'FP.B.DE47'){
                // setCalculatedResults(prev => ({
                //     ...prev,
                //     [name]: `${differenceInDays} ${name == 'FP.B.DE47'?' days since last normal menses':' days since delivery'}`,
                // }));

                if(name === 'FP.B.DE47'){
                    if(differenceInDays <= 7){
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.C.DE1': 'Yes'
                        }));
                }else{
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.C.DE1': 'No'
                    }));
                }

                if(differenceInDays > 35){
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE49': 'Yes'
                    }));
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE50': 'Yes'
                    }));
            }else{
                setFormValues(prevValues => ({
                    ...prevValues,
                    'FP.B.DE50': 'No'
                }));
                setFormValues(prevValues => ({
                    ...prevValues,
                    'FP.B.DE49': 'No'
                }));
            }
            }

                if (name === 'FP.B.DE3332') {
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE34': differenceInDays.toString()
                    }));

                    if(differenceInDays <= 42){
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.B.DE33': 'Yes'
                        }));

                        setCalculatedResults(prev => ({
                            ...prev,
                            [name]: `Client is postpartum`,
                        }));

                    }else{
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.B.DE33': 'No'
                        }));

                        setCalculatedResults(prev => ({
                            ...prev,
                            [name]: `Client is not postpartum`,
                        }));
                    }

                    if(differenceInDays <= 28){
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.C.DE4': 'Yes'
                        }));
                    }else{
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.C.DE4': 'No'
                        }));
                    }
                } else {
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE48': differenceInDays.toString()
                    }));
                }
            }
            
        }

        if(e.target.dataset.formId){

            try{
                const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Form-Decision/${e.target.dataset.formId}`))
               
                if(Array.isArray(data.data)){
                    const wan = data.data.map(res=>res.decisiondata)
                    value = Array.isArray(value)?value[value.length-1]:value
                    if(value == undefined){
                        return
                    }

                    const codes = [
                        "FP.B11.4",
                        "FP.B.DE25799",
                        "FP.B.DE25798",
                        "FP.B.DE25797",
                        "FP.B.DE25796",
                        "FP.B.DE25795",
                        "FP.B.DE25794",
                        "FP.B.DE25793",
                        "FP.B.DE25792",
                        "FP.B.DE25791",
                        "FP.B.DE25790",
                        "FP.B.DE257991",
                        "FP.B.DE257992",
                        "FP.B.DE257993",
                        "FP.B.DE257994",
                        "FP.B.DE257995",
                        "FP.B.DE257996",
                        "FP.B.DE257997",
                        "FP.B.DE257998",
                        "FP.B.DE257999",
                        "FP.B.DE258000",
                        "FP.B.DE258001",
                        "FP.B.DE258002"
                    ];
                    
                const needed = wan.filter(a => a.condition.trim().toLowerCase() === value?.trim().toLowerCase());
                console.log(needed)

                                if(needed.length != 1){
                                    var getAction = []
                                    var matches = []

                    
                                    const wantedConditions = needed
    .map(ult => ult.secondCondition.split('|').filter(Boolean)); 

wantedConditions.forEach((conditionGroup, index) => {
    conditionGroup.forEach(condition => {
        // Split by OR first to handle multiple subconditions
        const orConditions = condition.split('OR').map(cond => cond.trim());

        let orMatchFound = false; // Track if any OR condition matches

        orConditions.forEach(orCondition => {
            console.log(orCondition);

            const [quest, resp] = orCondition.split('=').map(part => part.trim());

            // Filter responses for matching questions or codes
            const allData = responses.filter(a => 
                a.quest === quest || codes.includes(a.quest) || a.actualquest.includes(quest)
            );

            // Normalize the response for comparison
            const normalizeResponse = (response) => {
                const trimmed = response?.trim().toLowerCase();
                if (trimmed === "true" || trimmed === "yes") return "true";
                if (trimmed === "false" || trimmed === "no") return "false";
                return trimmed;
            };

            // Find matches for the specific response
            const matchedResponses = allData.filter(a => 
                normalizeResponse(a.resp) === normalizeResponse(resp)
            );

            // If any match is found in the OR conditions, mark as matched
            if (matchedResponses.length > 0) {
                orMatchFound = true;
                const foundNeededElements = needed[index];
                getAction.push(foundNeededElements);
                matches = [...matches, ...matchedResponses];
            }
        });

        // If no match is found for any OR condition in this group, you can handle it here (optional).
        if (!orMatchFound) {
            console.log(`No match found for condition: ${condition}`);
        }
    });
});


                                    if(getAction.length != 0){
                                        if(
                                            getAction.filter(a=>a.action.includes('not')).length > 0
                                        ){
                                            disDecision(`
                                               ${getAction.filter(a=>a.action.includes('not'))[0]?.action.split('~')[0]} <br>
                                                ${getAction.filter(a=>a.action.includes('not'))[0]?.annotation}
                                                ${getAction.filter(a=>a.action.includes('not'))[0]?.action.split('~')[0]}
                                                `
                                            ,
                                            getAction.filter(a=>a.action.includes('not'))[0]?.action.split('~')[1])
                                        }else{
                                            disDecision(`
                                                ${getAction[0]?.action.split('~')[0]} <br>
                                                ${getAction[0]?.annotation} <br>
                                                `
                                            ,getAction[0].action.split('~')[1])
                                        }
                                    }
                                    
                                }else{
                                disDecision(`${needed[0]?.action.split('~')[0]}<br> ${needed[0]?.annotation}`
                                ,needed[0]?.action.split('~')[1])
                                }
                  }else{
                    // toast.info(data.data)
                }
              }catch(e){
                toast.error(e?.message)
              }
        }
        // setFormValues({
        //     ...formValues,
        //     [name]: value,
        // });
    };

    function disDecision(action, colorCode) {


        let texts;
       var diffs = action.split('<br>')    
        if (colorCode !== undefined) {
            if (colorCode.trim() === 'r') {
                texts = (
                    <div className='text-red-500'>
                        <div className='flex items-center'>
                        <div className='w-[10%]'>
                                <Dangerous className='mr-3 text-2xl' /> 
                            </div>

                            <div>
                                <span className='font-bold text-lg'>{diffs[0]}</span> <br/>
                                <p className='mt-2'>{<span dangerouslySetInnerHTML={{ __html: diffs[1] }} />}</p>
                            </div>
                            
                            {/* <span dangerouslySetInnerHTML={{ __html: action }} /> */}
                        </div>
                    </div>
                );
            } else if (colorCode.trim() === 'g') {
                texts = (
                    <div className='text-green-500'>
                        <div className='flex items-center'>
                        <div className='w-[10%]'>
                            <FaThumbsUp className='mr-3 text-2xl' /> 
                            </div>
                            <div>
                                <span className='font-bold text-lg'>{diffs[0]}</span> <br/>
                                <p className='mt-2'>{<span dangerouslySetInnerHTML={{ __html: diffs[1] }} />}</p>
                            </div>
                            {/* <span dangerouslySetInnerHTML={{ __html: action }} /> */}
                        </div>
                    </div>
                );
            } else if (colorCode.trim() === 'y') {
                texts = (
                    <div className='text-orange-500'>
                        <div className='flex items-center'>
                        <div className='w-[10%]'>
                            <Warning className='mr-3 text-2xl' /> 
                            </div>
                            <div>
                                <span className='font-bold text-lg'>{diffs[0]}</span> <br/>
                                <p className='mt-2'>{<span dangerouslySetInnerHTML={{ __html: diffs[1] }} />}</p>
                            </div>
                            {/* <span dangerouslySetInnerHTML={{ __html: action }} /> */}
                        </div>
                    </div>
                );
            }
        } else {
            texts = (
                <div className='text-blue-500'>
                    <div className='flex items-center'>
                    <div className='w-[10%]'>
                    <FaInfoCircle className='mr-3 text-2xl' /> 
                        </div>
                        <div>
                                <span className='font-bold text-lg'>{diffs[0]}</span> <br/>
                                <p className='mt-2'>{<span dangerouslySetInnerHTML={{ __html: diffs[1] }} />}</p>
                            </div>
                        
                    </div>
                </div>
            );
        }
    
        setDsActive(true);
        setCurrentField(texts); 
    }


    const shouldSkipField = (field) => {
        if (field.skip) {
            const conditions = field.skip.split('|').map((cond) => cond.trim());
            const transformedData = responses.reduce((acc, item) => {
                acc[item.quest] = item.resp;
                return acc;
            }, {});
    
            const combined = { ...formValues, ...transformedData };
    
            // Initialize groupedConditions correctly
            const groupedConditions = {};
    
            conditions.forEach((condition) => {
                const match = condition.match(/([^!=]+)(!?=)(.*)/);
                if (match) {
                    const [_, targetField, operator, targetValue] = match;
    
                    // Ensure groupedConditions[targetField] exists and push condition
                    if (!groupedConditions[targetField]) {
                        groupedConditions[targetField] = [];
                    }
                    groupedConditions[targetField].push({ operator, targetValue });
                } else {
                    console.warn(`Condition "${condition}" did not match expected pattern.`);
                }
            });
    
            // Evaluate grouped conditions
            return Object.entries(groupedConditions).some(([targetField, conditions]) => {
                // Check if any condition for the same targetField is true
                const currentValue = combined[targetField]?.toString().trim() || '';
    
                const allFalse = conditions.some(({ operator, targetValue }) => {
                    const result =
                        (operator === '!=' && currentValue !== targetValue) ||
                        (operator === '=' && currentValue === targetValue);
                    console.log(
                        `Condition: targetField=${targetField}, operator=${operator}, targetValue=${targetValue}, result=${result}`
                    );
                    return !result; // If any condition is true, skip this check
                });
    
                return !allFalse; // Skip field only if none of the conditions are false
            });
        }
    
        return false;
    };
    


    const handleSubmit = async (e) => {
        e.preventDefault();   
        setLoading(true);
        var arr = []
        const processedKeys = Object.keys(formValues);
        
        for (const key in formValues) {
            if (formValues.hasOwnProperty(key)) {
                var wan = key
                if(key.includes('_specify')){
                   wan = key.split('_')[0]
                }

              arr.push({
                "formId": data.find(a=>a.name === wan).formId,
                "patient": user.patientId,
                "response":convertArrayToString(formValues[key]),
                "addedBy": "f53101db-baf7-4f2c-4e44-08dbcd4df0dd",
                "encounterId": encounterId
              })
            }
          }

          data.forEach((item) => {
            if (!processedKeys.includes(item.name)) {
                var respond = 'No';
                if(item.name === 'FP.B.DE47' || item.name ===  'FP.B.DE48'){
                    respond = 'NA'
                }

                if(item.name === 'FP.C.DE2'){
                    respond = 'Yes'
                }

                if(item.name === 'FP.C.DE9'){
                    respond = 'Not Pregnant'
                }

                arr.push({
                    formId: item.formId,
                    patient: user.patientId,
                    response: respond,
                    addedBy: "f53101db-baf7-4f2c-4e44-08dbcd4df0dd",
                    encounterId: encounterId,
                });
            }
        });

          

          try{
            const response = await axios.post(rootUrl(`/api/FamilyPlaning/Add-Patient-Response`), arr)
            if(response.data[0]?.success){
              toast.success('Added successfully')
              closeModal()
              getResp()
            }
            setLoading(false);
          }catch{
            toast.error(e?.message)
            setLoading(false);
          }
      };

      function convertArrayToString(input) {
        if (Array.isArray(input)) {
          return input.join(', ');
        } else {
          return input.toString(); 
        }
      }


   
      const filterAndSortOptions = (options, fieldName, userGender) => {
        const keywords = ["male", "female", "no method", "withdrawal"];
        const isMaleUser = userGender?.toLowerCase() === 'male';
    
        // Define explicit priorities for specific options to appear first
        const prioritizedOptions = ["none", "no method", "not applicable"];
    
        // Helper function to capitalize the first letter of each word
        const capitalizeWords = (str) =>
            str.replace(/\b\w/g, char => char.toUpperCase());
    
        const optionPriorities = option => {
            const lowerCaseOption = option.optionType.toLowerCase();
    
            // Check if the option contains the word "other" and assign it the lowest priority
            if (lowerCaseOption.includes('other')) {
                return prioritizedOptions.length + 2; // This ensures "other" options are always last
            }
    
            if (prioritizedOptions.includes(lowerCaseOption)) {
                return prioritizedOptions.indexOf(lowerCaseOption) + 1;
            }
    
            return prioritizedOptions.length + 1; // Default for other options
        };
    
        return options
            .map(option => ({
                ...option,
                optionType: capitalizeWords(option.optionType) // Apply capitalization to each optionType
            }))
            .filter(option => {
                const optionType = option.optionType.toLowerCase();
                const isMaleOnlyOption = optionType.includes('male') && !optionType.includes('female');
                const isVasectomyOption = optionType === 'vasectomy';
                const isMethodOption = optionType === 'no method' || optionType === 'withdrawal';
                const isMatch = keywords.some(keyword => fieldName.toLowerCase().includes(keyword));
    
                if (isMatch && isMaleUser) {
                    return isMaleOnlyOption || isVasectomyOption || isMethodOption;
                } else {
                    return (!isMaleOnlyOption && !isVasectomyOption) || isMethodOption;
                }
            })
            .sort((a, b) => {
                const aPriority = optionPriorities(a);
                const bPriority = optionPriorities(b);
    
                // Sort by priority if they differ
                if (aPriority !== bPriority) {
                    return aPriority - bPriority;
                }
    
                // Fallback to sorting by optionId if priorities are the same
                return a.optionId - b.optionId;
            });
    };
    
    

    const filteredFields = useMemo(() => {
        return data.filter(field => {
            if (shouldSkipField(field)) return false;
            if (field.criteria.toLowerCase() === 'adolescent' && user.age > 19) return false;
            if (field.criteria.toLowerCase() === 'female' && user.gender.toLowerCase() === 'male') return false;
            return true;
        });
    }, [data, user, formValues]);

    const groupByCategory = (fields) => {
        return fields.reduce((acc, field) => {
            (acc[field.cateory] = acc[field.cateory] || []).push({
                ...field,
                options: filterAndSortOptions(field.options, field.name, user.gender)
            });
            return acc;
        }, {});
    };


    const isFieldDisabled = (name) => {
        if (name === 'FP.B.DE33' || name === 'FP.B.DE34' || name === 'FP.B.DE48' || 
            name === 'FP.C.DE1' ||
            name === 'FP.B.DE49'
        ) {
            return true; 
        }
        return false;
    };
    
    const [groupedFields, setGroupedFields] = useState({});

    useEffect(() => {
        setGroupedFields(groupByCategory(filteredFields));
    }, [filteredFields]);
    


    const defaultNo = 
        [
        'FP.B.DE29',
        'FP.B.DE29998',
        'FP.A.DE3',
        'FP.A.DE54',
        'FP.B.DE2578',
        'FP.B.DE41',
        'FP.B.DE46',
        'FP.B.DE411',
        'FP.A.DE55',
        ]


    useEffect(()=>{
        if(data.filter(a=>defaultNo.includes(a.name)))
            data.map((res)=>{
                if(defaultNo.includes(res.name)){
                    setFormValues(prevValues => ({
                        ...prevValues,
                        [res.name]: 'No',
                    }));
                }
        })
    },[])

    const keywords = [
        'FP.C.DE140',
        'FP.B.DE30',
        'FP.B.DE216',
        'FP.C.DE73',
        'FP.B.DE51',
    ]
    const [otherSelected, setOtherSelected] = useState(false);
    
    const [showOtherInputs, setShowOtherInputs] = useState({}); // State to track 'other' inputs for each field

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const fieldName = event.target.name;
        // Update the form values for the selected field
        handleChange(event);
        setShowOtherInputs(prevState => ({
            ...prevState,
            [fieldName]: selectedValue.toLowerCase().includes("other") || selectedValue.toLowerCase().includes("specify")
        }));
    };

    const handleOtherSelection = (selectedOptions) => {
        setOtherSelected(
            Array.isArray(selectedOptions) &&
            selectedOptions.some(option => 
                option?.value?.toLowerCase().includes("other") && 
                option?.label?.toLowerCase().includes("specify")
            )
        );
    };
    
 
    return (
        <div className="w-full">
            {dsActive ? (
                <div className="fixed shadow-xl p-3 w-[64.5%] border z-20 bg-gray-200">
                    <div className="flex justify-between text-red-500">
                        <span></span>
                        <FaTimes onClick={() => setDsActive(!dsActive)} className="text-lg" />
                    </div>
                    <div className="overflow-y-scroll max-h-[500px]">
                        {currentField}
                    </div>
                </div>
            ) : null}
    

            <form onSubmit={handleSubmit}>
                {Object.keys(groupedFields).length === 0 ? (
                    <p className="text-lg">No questions to display</p>
                ) : (
                    Object.entries(groupedFields).map(([cateory, fields]) => (
                        <div key={cateory} className="mb-6">
                            <h2 className="mt-3 text-xl font-semibold text-gray-800 mb-4 border-b border-gray-400 pb-1">{cateory}</h2>
                            <div className="grid grid-cols-2 gap-4">
                                {fields.map((field, index) => {
                                    if (field == null) return null;
                                    return (
                                        <div key={index} className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                                                {field.label.replace((`${field.name}.`), "")} {field.required.toLowerCase() === 'yes' || field.required === 'Conditional' ? '*' : ''}
                                                {field.decisionSupports.length > 0 ? (
                                                    <FaInfoCircle
                                                        ref={el => (iconRefs.current[index] = el)}
                                                        title="click to view annotations"
                                                        className="text-customBlue ml-2"
                                                    />
                                                ) : null}
                                            </label>
    
                                            {field.type === 'select' && (
                <div>
                    <select
                        name={field.name}
                        onChange={handleSelectChange} // Use the updated change handler
                        value={formValues[field.name] || ''}
                        data-form-id={field.formId}
                        className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                    >
                        <option value="">Select an option</option>
                        {field.options.map(option => (
                            <option key={option.optionId} value={option.optionType}>
                                {option.optionType}
                            </option>
                        ))}
                    </select>

                    {showOtherInputs[field.name] && (
                        <div className="mt-2">
                            <input
                                type="text"
                                name={`${field.name}_specify`}
                                onChange={handleChange}
                                value={formValues[`${field.name}_specify`] || ''}
                                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline mt-2"
                                placeholder="Please specify"
                            />
                        </div>
                    )}
                </div>
            )}
    
            {field.type.toLowerCase() === 'text' && (
                <input
                    type="text"
                    name={field.name}
                    onChange={handleChange}
                    value={formValues[field.name] || ''}
                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                    disabled={isFieldDisabled(field.name)}
                />
            )}
    
            {field.type === 'textarea' && (
                <textarea
                    name={field.name}
                    onChange={handleChange}
                    value={formValues[field.name] || ''}
                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                />
            )}
                                           
                                        {field.type === 'checkbox' && (
                                            field.options.length == -5?
                                        <div>
                                            {field.options
                                                .map(option => (
                                                    <div key={option.optionId} className="mb-2">
                                                        <label className="block text-gray-700 text-sm">
                                                            <input
                                                                type="checkbox"
                                                                name={field.name}
                                                                value={option.optionType}
                                                                onChange={(e) => handleChange(e, field.name, option.optionType)}
                                                                data-form-id={field.formId}
                                                                checked={formValues[field.name]?.includes(option.optionType)}
                                                                className="mr-2 leading-tight"
                                                            />
                                                            {option.optionType}
                                                        </label>
                                                        {option.optionType.toLowerCase().includes("other") && option.optionType.toLowerCase().includes("specify") 
                                                            ? formValues[field.name]?.includes(option.optionType) && (
                                                            <input
                                                                type="text"
                                                                name={`${field.name}_specify`}
                                                                value={formValues[`${field.name}_specify`] || ''}
                                                                onChange={handleChange}
                                                                placeholder="Please specify"
                                                                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 mt-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))
                                            }
                                        </div>:<>
                                         <Select
                                         options={[
                                           ...field.options
                                             .map(option => ({ value: option.optionType, label: option.optionType }))
                                         ]}
                                         isMulti={field.name !== 'FP.B.DE51' || field.name !== 'FP.B.DE29998' || field.name !== 'FP.B.DE30' }
                                         placeholder="Select options..."
                                         onChange={selectedOptions => {
                                           handleMultiSelectChange(selectedOptions, field);
                                           handleOtherSelection(selectedOptions);
                                         }}
                                         value={field.options
                                           .filter(option => formValues[field.name]?.includes(option.optionType))
                                           .map(option => ({ value: option.optionType, label: option.optionType }))
                                         }
                                       />
                                       
                                       {otherSelected && (
                                         <input
                                           type="text"
                                           placeholder="Please specify"
                                           name={`${field.name}_specify`}
                                           className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                           value={formValues[`${field.name}_specify`] || ''}
                                           onChange={handleChange}
                                         />
                                       )}
                                            </>
                                    )}

                                {field.type === 'radio' && (
                                    <div>
                                        {field.options
                                            .sort((a, b) => {
                                                if (a.optionType === "Yes") return -1;
                                                if (b.optionType === "Yes") return 1;
                                                return 0;
                                            })
                                            .map(option => (    
                                                <label key={option.optionId} className="block text-gray-700 text-sm mb-2">
                                                    <input
                                                        type="radio"
                                                        name={field.name}
                                                        value={option.optionType}
                                                        onChange={handleChange}
                                                        data-form-id={field.formId}
                                                        checked={formValues[field.name] === option.optionType}
                                                        className="mr-2 leading-tight"
                                                        required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                        disabled={isFieldDisabled(field.name)}
                                                    />
                                                    {option.optionType}
                                                </label>
                                            ))
                                        }
                                    </div>
                                )}

                                            {field.type === 'date' && (
                                                <input
                                                    type="date"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                />
                                            )}
    
                                            {field.type === 'time' && (
                                                <input
                                                    type="time"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                />
                                            )}

                                            {field.type === 'number' && (
                                                <input
                                                    type="number"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                    disabled={isFieldDisabled(field.name)}
                                                />
                                            )}
                                            {calculatedResults[field.name] && (
                                                    <p className="mt-2 text-sm text-blue-600 font-bold">
                                                        {calculatedResults[field.name]} 
                                                    </p>
                                                )}

                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))
                )}
    
                <hr className="my-2" />
                <div className="flex justify-center">
                    <button
                        disabled={loading}
                        type="submit"
                        className="w-[25%] mt-4 w-full bg-green-500 text-white px-4 py-2 rounded-md"
                    >
                        {loading ? <FaSpinner /> : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    );
    
  
}
