import React from 'react'

export default function Login() {
  return (
    <>
    <div className='flex justify-center items-center h-screen'>
        <div className='text-center'>
            <img src="/logo1.png" className='w-1/2 mx-auto' alt="Smartcare Plus Logo" />
            <span className='font-bold text-3xl block mt-4'>Smartcare Plus</span>
            <input type="text" placeholder='Type your email address or username' className='border p-3 border-gray-500 m-3 w-full' /> <br/>
            <input type="text" placeholder='Type your password' className='border p-3 border-gray-500 m-3 w-full' /> <br/>
            <button className='p-3 m-3 bg-blue-500 text-white w-full'>Login</button>
            <span className='float-right text-blue-500'>Forgot your Password?</span>
        </div>
    </div>

    <div className="p-2 mt-4 fixed bottom-0 w-full bg-gray-200">
    <footer className="flex justify-center">
    Copyright &copy; {new Date().getFullYear()} Ministry of Health. All Rights Reserved.
    </footer>
    </div>
    
    </>
  )
}
