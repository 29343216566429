import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Usercard from './usercard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formatDate, rootUrl } from '../../helpers';
import SummaryDiv from './encounter/Summary';
import ModalDefault from '../../components/ModalDefault';

export default function FP() {

    const [users, setUsers] = useState({});

    const { patientId } = useParams();

    const [encounters, setEncounters] = useState([]);
    const [loader, setLoader] = useState(false);
    const [aspects, setAspects] = useState([])
    const [activities, setActivity] = useState([])
    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})
    const [responses, setResponses] = useState([])


    const getAspect = async () =>{
        try{
          const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Aspects`))
          setAspects(data.data.serviceActivities)
        }catch(e){
          toast.error(e?.message)
        }
          
        }

        const handleClose = () => {
            setModal({...modal, open: false })   
        }

        const getResp = async (eid)=>{
            try{
              const data = await axios.get(rootUrl(`/api/FamilyPlaning/Responses-by -encounter/${eid}`))
              var arr = []
              data.data.map((res)=>{
                arr.push({
                  activityId:res.activityId,
                  aspectId:res.aspectId,
                  [res.serviceForms.description]:res.response,
                  formId:res.serviceForms.formID,
                  quest:res.serviceForms.label,
                  resp:res.response
                })
              })
              setResponses(arr);
            }catch(e){
              toast.error(e?.message)
            }
          }

    const fetchData = async () =>{
        try{
            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-patients`))
            var uza = data.data.filter(a=>a.patient.patientId == patientId)[0].patient
            setUsers(uza)
        }catch(e){
            toast.error(e?.message)
        }

        try{
            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Encounters-by -patient/${patientId}`))
            setEncounters(data.data)
        }catch(e){
            toast.error(e?.message)
        }

    }

    
    const navigate = useNavigate();
    
    useEffect(()=>{
        fetchData()
        getAspect()
    },[])


    const addEncounter = async () =>{

        const data =   {
            patientId:patientId,
            facility:localStorage.getItem('Facility')
        }
        try{
            const response = await axios.post(rootUrl(`/api/FamilyPlaning/FP-encounter`), data)
            if(response.status == 200){
                localStorage.setItem('encounterId',response.data.encounterId)

                var wanted = {
                    patientID:patientId,
                    encounterID:response.data.encounterId,
                    invoiceNo:'9998212',
                    chartOfAccounts:[{accountID:'6d9de93d-16c4-453e-9548-80716aae86ab'}]
                }
                const resp = await axios.post(rootUrl(`/api/TransactionsController/add-bills`), [wanted])
        
                navigate(`/app/encounter/${patientId}`)
            }else{
                toast.error('Failed to post encounter')
            }

        }catch(e){
            toast.error(e?.message)
        }
    
    }

    function summary(eid){
        getResp(eid)
      }

      useEffect(()=>{
        if(responses.length > 0){
            setModal({
                title:"Visit Summary",
                open:true,
                size:'sm',
                body:<SummaryDiv data={responses} activities={activities} aspects={aspects} user={users}/>
                  })
        }
      }, [responses])

      
    
  return (
    <div className="p-10">
        {
            users.patientId?(
                <>
                    <Usercard user={users} action="Add Encounter" disabled={loader} onClick={addEncounter} />

                <span className='font-semibold text-2xl mt-5'>Previous Encounters</span>
                {
                    encounters && (
                        <div className='my-5 grid grid-cols-2'>
                            {
                        encounters.map((res)=>(
                            <div onClick={()=>summary(res.encounterId)} className=" flex justify-between p-5 text-blue-900 rounded-2xl m-3 border border-customBlue  cursor-pointer hover:bg-gray-100">
                                  <span>{res.facility} </span>  {formatDate(res.dateAddedd.substring(0,10))}
                            </div>
                        ))
                    }
                        </div>
                    )
                }
                </>
            ):
            <></>
        }
        <ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />
        
    </div>
  )
}
