import React, { useEffect, useState, useRef  } from 'react'
import { calculateAge, rootUrl } from '../../../helpers';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Accordion,
  AccordionHeader, 
  AccordionBody 
} from "@material-tailwind/react";
import { FaCashRegister, FaChartArea, FaFileExcel, FaFilePdf } from 'react-icons/fa';

export default function Reports() {

    const [users, setUsers] = useState([]);
    const [aspects, setAspects] = useState([]);
    const [responses, setResponses] = useState([])
    const [groupedResponses, setGroupedResponses] = useState([])

    const fetchData = async () =>{
        try{

            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-patients`))
            setUsers(data.data)
        }catch(e){
            toast.error(e?.message)
        }
    }

    const getAspect = async () =>{
      try{
        const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Aspects`))
        var arr = []
        data.data.serviceActivities.map((res)=>{
          arr.push({
            id:res.aspectId,
            name:res.aspectName
          })
        })
        setAspects(arr)
      }catch(e){
        toast.error(e?.message)
      }
      } 

    const getResp = async ()=>{
        try{
          const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Responses`))
          var arr = []
          data.data.map((res)=>{
            arr.push({
              aspect:res.aspectId,
              response:res.response,
              code:res.serviceForms.label,
              name:res.serviceForms.description,
              patientId:res.patientId
            })
          })
          setResponses(arr);
        }catch(e){
          toast.error(e?.message)
        }
        
      }

      

      useEffect(()=>{

        getResp()
        fetchData()
        getAspect()

      },[])

      const [activeTab, setActiveTab] = useState('register');
      const navTabs = [
        {
          label:"Register",
          value:"register",
          icon:<FaCashRegister/>,
          desc:<Register users={users} data={responses}/>,
        },
        {
          label:"HAI2",
          value:"HAI2",
          icon:<FaChartArea/>,
          desc:<Report users={users} data={responses} aspects={aspects}/>,
        }
      ]

  return (
    <div className='p-5'>
    <div className="overflow-x-auto">


      <div className='flex justify-center my-5'>

        <input type="date" className='border p-2 rounded-2xl m-3' />
        <input type="date" className='border p-2 rounded-2xl m-3' />

      </div>
      
    <Tabs value="register">
            <TabsHeader className="bg-gray-100 rounded-lg border shadow">
                {navTabs.map(({ label, value, icon }) => (
                <Tab    key={value}
                        value={value}
                        onClick={() => setActiveTab(value)}
                        className={`flex items-center gap-2 text-blue-gray-700 hover:bg-blue-gray-50 active:bg-blue-gray-100 rounded-lg p-2 transition
                            ${
                                activeTab === value
                                    ? "bg-white text-blue-gray-700 rounded"
                                    : "text-blue-gray-700 hover:bg-blue-gray-200 active:bg-blue-gray-300"
                            }
                        `}
             >
                    <div className="flex items-center gap-2">
                    {/* {React.createElement(icon, { className: "w-5 h-5" })} */}
                    <span className="font-medium flex items-center"> <span className='mr-1'>{icon} </span> {label}</span>
                    </div>
                </Tab>
                ))}
            </TabsHeader>
            <TabsBody>
                {navTabs && navTabs.map((res) => (
                <TabPanel key={res.value} value={res.value} className="p-4">
                     <p className="text-blue-gray-700">{res.desc}</p>
                </TabPanel>
                ))}
            </TabsBody>
    </Tabs>

    </div>
    </div>
  );
}

function Register({users, data}){

  const dataArray = [
    "FP.A.DE7",
    "FPA.DE1",
    "FP.A.DE8",
    "FP.A.DE9",
    "FP.A.DE20",
    "FP.A.DE10",
    "FP.A.DE16",
    "FP.A.DE40",
    "FP.B.DE31",
    "FP.B.DE32",
    "FP.C.DE8",
    "FP.C.DE10",
    "FP.B.DE42",
    "FP.B.DE34",
    "FP.B.DE34",
    "FP.B.DE34",
    "FP.C.DE70",
    "FP.C.DE71",
    "FP.C.DE68",
    "FP.C.DE69",
    "FP.C.DE72",
    "FP.C.DE21",
    "FP.C.DE46",
    "FP.C.DE23",
    "FP.C.DE24",
    "FP.C.DE18",
    "FP.C.DE15",
    "FP.C.DE16",
    "FP.C.DE81",
    "FP.C.DE82",
    "FP.C.DE82",
    "FP.C.DE82",
    "FP.C.DE87",
    "FP.C.DE88",
    "FP.C.DE79",
    "FP.C.DE78",
    "FP.C.DE80",
    "FP.C.DE80",
    "FP.C.DE77",
    "FP.C.DE76",
    "FP.C.DE74",
    "FP.C.DE75",
    "FP.C.DE92",
    "FP.C.DE91",
    "FPA.DE4",
    "FP.D.DE8",
    "FP.D.DE2",
    "FP.D.DE13",
  ];
  
  var wanted = []
 data.map((res)=>{
  dataArray.map((sult)=>{
    if(res.code == sult){
      wanted.push(res)
    }
  })
  }) 

  return(
    <div>
    <table className="min-w-full mt-5">
    <thead className="bg-gray-200">
    <tr>
    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Name
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Address
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Date of birth/Age
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Sex
          </th>
      {
        wanted.map((res)=>(
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.name}
          </th>
        )) 
      }
      </tr>
      
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {users.filter(a=>wanted.map(ut=>ut.patientId).includes(a.patientId)).map((res)=>(
        <tr>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.firstname} {res.lastname}
          </td>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.address}
          </td>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {res.dob?.substring(0,10)} / {calculateAge(res.dob)}
          </td>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.gender}
          </td>

          {
        wanted.filter(b=>b.patientId == res.patientId).map((wan)=>(
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {wan.response}
          </th>
        )) 
      }
        </tr>
      ))}
    
    </tbody>
  </table>
  </div>
  )

}


function Report({users, data, aspects}) {

  const tableRef = useRef();

  const optionsArray = [
    "Male condoms",
    "Female condoms",
    "Combined oral contraceptives (COCs)",
    "Progestogen-only pills (POPs)",
    "DMPA-IM",
    "DMPA-SC",
    "Norethisterone enanthate (NET-EN)",
    "Levonorgestrel (LNG) two-rod implant",
    "Etonogestrel (ETG) one -rod implant",
    "Copper-bearing intrauterine device (Cu-IUD)",
    "Levonorgestrel-releasing IUD (LNG-IUD)",
    "Female sterilization",
    "Male sterilization",
    "Copper-bearing intrauterine device (Cu-IUD) removal",
    "Levonorgestrel intrauterine device (LNG-IUD) removal",
    "Unspecified implant removal",
    "Etonogestrel (ETG) one-rod implant removal",
    "Levonorgestrel (LNG) two-rod implant removal"
  ];

  const screens = [
    "Screened for Breast Cancer",
    "Screened for Cervical Cancer",
    "Screened for Prostate Cancer",
    "Screened for TB",
    "Treated for STIs",
    "HIV screening: Screened for HTS Need",
    "HIV Test conducted"
  ]

  const first = [
    "New Acceptor",
    "Subsequent Visit",
    "Restart",
    "Discontinue"
  ];

  const nums = ["< 15 years", "15-19 years", "20-24 years", "25+ years"];
  const need = [];

  data.forEach((res) => {
   
    const user = users.find((a) => a.patientId === res.patientId);
    if (user) {
      need.push({
        aspect: res.aspect,
        name: res.name,
        code: res.code,
        patientId: res.patientId,
        response: res.response,
        age: calculateAge(user.dob),
        gender: user.gender,
      });
    }
  });

  const exportTableToExcel = () => {
    const table = tableRef.current;
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, 'Report.xlsx');
  };


  const exportTableToPDF = () => {
    const doc = new jsPDF();
  
    doc.text('Report', 20, 10);
    
    doc.autoTable({
      html: tableRef.current,
      startY: 20,
      theme: 'grid',
      headStyles: {
        fillColor: '#33663',
      },
      didDrawPage: (data) => {
        if (data.pageCount > 1) {
          data.settings.margin.top = 20;
        }
      },
      margin: { top: 20 },
      styles: { overflow: 'linebreak' },
      willDrawCell: function (data) {
        if (data.section === 'head') {
          data.cell.styles.fontSize = 0; 
        }
      },
      showHead: 'firstPage'
    });
  
    doc.save('Report.pdf');
  };


  return (
    <div>

      

      <div className='flex justify-between'>

      <span className='font-bold text-lg'>
        {localStorage.getItem('Facility')}
      </span>


        <div>
            <button onClick={exportTableToExcel} className="bg-blue-500 text-white p-2 m-2 rounded">
            <FaFileExcel/>
          </button>
          <button onClick={exportTableToPDF} className="bg-orange-500 text-white p-2 m-2 rounded">
            <FaFilePdf/>
            </button>
        </div>
      
      </div>
     

      <table ref={tableRef} className="min-w-full mt-2">
  <thead className="bg-gray-200">
    <tr>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      [A] Attendances
      </th>
      {first.map((res, index) => (
        <th
          key={index}
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {res}
        </th>
      ))}
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">
    {nums.map((ult, indexUlt) => (
      <tr key={indexUlt}>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {ult}
        </td>
        {first.map((res, indexRes) => (
          <td
            key={indexRes}
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {(() => {
                          if (ult === '< 15 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age < 15
                            ).length;
                          } else if (ult === '15-19 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 15
                            ).length;
                          } else if (ult === '20-24 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 20
                            ).length;
                          } else if (ult === '25+ years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 25
                            ).length;
                          } else {
                            return 0;
                          }
                        })()}
          </td>
        ))}
      </tr>
    ))}
<tr className='bg-gray-100'>
                <td className='p-4 m-2' colSpan={5}></td>
              </tr>
<tr className="bg-gray-200">
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      [B] Family Planning Methods Provided
      </th>
      {nums.map((res, index) => (
        <th
          key={index}
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {res}
        </th>
      ))}
    </tr>

    {optionsArray.map((res) => (
                <tr key={`${res}`}>
                  <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {res}
                  </td>

    {nums.map((ult, indexRes) => (
          <td
            key={indexRes}
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {(() => {
                          if (ult === '< 15 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age < 15
                            ).length;
                          } else if (ult === '15-19 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 15
                            ).length;
                          } else if (ult === '20-24 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 20
                            ).length;
                          } else if (ult === '25+ years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 25
                            ).length;
                          } else {
                            return 0;
                          }
                        })()}
          </td>
        ))}
                </tr>
              ))} 

              <tr className='bg-gray-100'>
                <td className='p-4 m-2' colSpan={5}></td>
              </tr>

<tr className="bg-gray-200">
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      [C] Other Services Provided in FP
      </th>
      {nums.map((res, index) => (
        <th
          key={index}
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {res}
        </th>
      ))}
    </tr>
    {screens.map((res) => (
                <tr key={`${res}`}>
                  <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {res}
                  </td>

    {nums.map((ult, indexRes) => (
          <td
            key={indexRes}
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {(() => {
                          if (ult === '< 15 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age < 15
                            ).length;
                          } else if (ult === '15-19 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 15
                            ).length;
                          } else if (ult === '20-24 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 20
                            ).length;
                          } else if (ult === '25+ years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 25
                            ).length;
                          } else {
                            return 0;
                          }
                        })()}
          </td>
        ))}
                </tr>
              ))} 
   
  </tbody>
</table>

        </div>
      
    
  );
}


