import React, { useState, useEffect } from 'react';
import { Select, Option } from "@material-tailwind/react";
import { Link, useNavigate  } from 'react-router-dom';
const facilitiesData = 
[
    {
        "oid": 77,
        "description": "Sepande Mini-Hospital",
        "districtId": 50
    },
    {
        "oid": 3121,
        "description": "Mapanza Rural Health Centre",
        "districtId": 50
    },
    {
        "oid": 3122,
        "description": "Simaubi Rural Health Centre",
        "districtId": 50
    },
    {
        "oid": 3123,
        "description": "Choma General Hospital",
        "districtId": 50
    },
    {
        "oid": 3124,
        "description": "Kalomo District Hospital",
        "districtId": 51
    },
    {
        "oid": 3125,
        "description": "Kalomo Rural Health Centre",
        "districtId": 51
    },
    {
        "oid": 3126,
        "description": "Livingstone General Hospital",
        "districtId": 52
    },
    {
        "oid": 3127,
        "description": "Maramba Clinic",
        "districtId": 52
    },
    {
        "oid": 3128,
        "description": "Mazabuka District Hospital",
        "districtId": 53
    },
    {
        "oid": 3129,
        "description": "Mazabuka Rural Health Centre",
        "districtId": 53
    }
]

const districts = [
    { "id": 50, "name": "Choma District", "provinceId": 9 },
    { "id": 51, "name": "Kalomo District", "provinceId": 9 },
    { "id": 52, "name": "Livingstone District", "provinceId": 9 },
    { "id": 53, "name": "Mazabuka District", "provinceId": 9 }
]

const provinces =[
    { "id": 1, "name": "Central Province" },
    { "id": 2, "name": "Copperbelt Province" },
    { "id": 3, "name": "Eastern Province" },
    { "id": 4, "name": "Luapula Province" },
    { "id": 5, "name": "Lusaka Province" },
    { "id": 6, "name": "Muchinga Province" },
    { "id": 7, "name": "Northern Province" },
    { "id": 8, "name": "North-Western Province" },
    { "id": 9, "name": "Southern Province" },
    { "id": 10, "name": "Western Province" }
]


const FacilitySearch = () => {

    const navigate = useNavigate();

    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const [filteredFacilities, setFilteredFacilities] = useState([]);


    useEffect(() => {
        if (selectedProvince) {
            setFilteredDistricts(districts.filter(d => d.provinceId === parseInt(selectedProvince)));
        } else {
            setFilteredDistricts([]);
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedDistrict) {
            setFilteredFacilities(facilitiesData.filter(f => f.districtId === parseInt(selectedDistrict)));
        } else {
            setFilteredFacilities([]);
        }
    }, [selectedDistrict]);

    const chooseFacility = (value) => {
        localStorage.setItem('Facility', value);
        navigate('app/search');
    };

    return (
        <div className="max-w-xl mx-auto p-4">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Select Province</label>
                <select
                    value={selectedProvince}
                    onChange={(e) => setSelectedProvince(e.target.value)}
                    className="block rounded-2xl w-full bg-white border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select a Province</option>
                    {provinces.map(province => (
                        <option key={province.id} value={province.id}>
                            {province.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Select District</label>
                <select
                    value={selectedDistrict}
                    onChange={(e) => setSelectedDistrict(e.target.value)}
                    className="block rounded-2xl w-full bg-white border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    disabled={!selectedProvince}
                >
                    <option value="">Select a District</option>
                    {filteredDistricts.map(district => (
                        <option key={district.id} value={district.id}>
                            {district.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Search Facility</label>
                <select
                    onChange={(e) => chooseFacility(e.target.value)}
                    className="block rounded-2xl w-full bg-white border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    disabled={!selectedDistrict}
                >
                    <option value="">Select a facility</option>
                    {filteredFacilities.map(facility => (
                        <option key={facility.description} value={facility.description}>
                            {facility.description}
                        </option>
                    ))}
                </select>
            </div>

        </div>
    );
};

export default FacilitySearch;
