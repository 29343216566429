
import React, { useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { calculateAge } from '../../../helpers';


function SummaryDiv({data, activities, aspects, user}){
    const [expandedAspects, setExpandedAspects] = useState({});
    const toggleAspect = (aspectId) => {
      setExpandedAspects((prevState) => ({
        ...prevState,
        [aspectId]: !prevState[aspectId],
      }));
    };
  
    return (
      <div>
     {aspects.map((res) => (
  <div key={res.aspectId}>

    {(res.aspectName !== "Heads" || calculateAge(user.dob) <= 19) && (
      <div
        className='font-semibold text-lg cursor-pointer flex justify-between items-center'
        onClick={() => toggleAspect(res.aspectId)}
      >
        <span>{res.aspectName}</span>
        {expandedAspects[res.aspectId] ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
    )}
    <hr className='my-3' />
    {expandedAspects[res.aspectId] && (
      <ul className='mb-3'>
      {data
        .filter((a) => a.aspectId === res.aspectId)
        .map((sult, index) => (
          <li key={index} className="list-disc pl-5">
            {Object.entries(sult).map(([key, value]) => (
              
                !(key.includes('Id') || key === 'quest' || key === 'resp' || key === 'actualquest') && (
                  <div key={key}> 
                    <span className='my-2 mr-2'>{key}:</span>
                    <strong>{value}</strong>
                  </div>
                )
            ))}
          </li>
        ))}
      
    </ul>
    )}
  </div>
))}
      </div>
    );
  }

  
  export default SummaryDiv;