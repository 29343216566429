import React, {useEffect, useState} from 'react'
import {
    Spinner,
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Accordion,
    AccordionHeader, 
    AccordionBody 
  } from "@material-tailwind/react";
import { FaCogs, FaListAlt, FaPlus } from 'react-icons/fa';
import axios from 'axios';
import { formatToZMW, rootUrl } from '../../../helpers';
import { toast } from 'react-toastify';
import ModalDefault from '../../../components/ModalDefault';
import FormGenerator from '../../../components/formGenerator';
export default function Bills() {
    const [activeTab, setActiveTab] = useState('coa');
    const navTabs = [
      {
        label:"Chart of accounts",
        value:"coa",
        icon:<FaListAlt/>,
        desc:<ChartOfAccount/>,
      },
      {
        label:"Place Holder",
        value:"ph",
        icon:<FaCogs/>,
        desc:'',
      }
    ]
  return (
    <div>

<Tabs value="coa">
            <TabsHeader className="bg-gray-100 rounded-lg border shadow">
                {navTabs.map(({ label, value, icon }) => (
                <Tab    key={value}
                        value={value}
                        onClick={() => setActiveTab(value)}
                        className={`flex items-center gap-2 text-blue-gray-700 hover:bg-blue-gray-50 active:bg-blue-gray-100 rounded-lg p-2 transition
                            ${
                                activeTab === value
                                    ? "bg-white text-blue-gray-700 rounded"
                                    : "text-blue-gray-700 hover:bg-blue-gray-200 active:bg-blue-gray-300"
                            }
                        `}
             >
                    <div className="flex items-center gap-2">
                    {/* {React.createElement(icon, { className: "w-5 h-5" })} */}
                    <span className="font-medium flex items-center"> <span className='mr-1'>{icon} </span> {label}</span>
                    </div>
                </Tab>
                ))}
            </TabsHeader>
            <TabsBody>
                {navTabs && navTabs.map((res) => (
                <TabPanel key={res.value} value={res.value} className="p-4">
                     <p className="text-blue-gray-700">{res.desc}</p>
                </TabPanel>
                ))}
            </TabsBody>
    </Tabs>


    </div>
  )
}

function ChartOfAccount(){
    const [coas, setCoas] = useState([])
    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})


    const fetchData = async ()=>{
        try{
            const data =  await axios.get(rootUrl(`/api/TransactionsController/Get-Charted-Accounts`))
            setCoas(data.data)
        }catch(e){
            toast.error(e?.message)
        }
       
    }

    useEffect(()=>{
        fetchData()
    },[])

    const handleClose = () => {
        setModal({...modal, open: false })   
    }

   

    function addCoa(){

        const fields = [
            {name: 'name', label: 'Name', type: 'text', required:true },
            {name: 'code', label: 'Code', type: 'text', required:true },
            {name: 'amount', label: 'amount', type: 'number', required:true },
        ]

       async function onsubmit(data){
            try{
                const response =  await axios.post(rootUrl(`/api/TransactionsController/add-chart-of-accounts`), [data])
                console.log(response)

                if(response.status === 200){
                    toast.success("Added successful")
                    setCoas(prevArray => [...prevArray, ...response.data]);
                    handleClose()
                }
            }catch(e){
                toast.error(e?.message)
            }
        }

        setModal({
            title:'Add Chart of Account',
            open:true,
            size:'sm',
            body:<FormGenerator fields={fields}  onSubmit={onsubmit} />
    
        })

    }

    return (

        <div className='bg-white p-4'>
            <div className='flex justify-between'>
                <div></div>
                <button onClick={addCoa} className='bg-blue-500 text-white px-3 py-1 rounded flex items-center'> <FaPlus/> Add</button>
            </div>
            
            {
                coas && 
                <table className="min-w-full bg-white border border-gray-300 mt-5">
                <thead>
                  <tr className='bg-customBlue text-white'>
                    <th className="px-4 py-2 text-left text-sm font-semibold border-b">Sno</th>
                    <th className="px-4 py-2 text-left text-sm font-semibold border-b">Code</th>
                    <th className="px-4 py-2 text-left text-sm font-semibold border-b">Name</th>
                    <th className="px-4 py-2 text-left text-sm font-semibold border-b">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {coas.map((account, count) => (
                    <tr key={account.code} className="hover:bg-gray-100">
                      <td className="px-4 py-2 border-b">{++count}</td>
                      <td className="px-4 py-2 border-b">{account.code}</td>
                      <td className="px-4 py-2 border-b">{account.name}</td>
                      <td className="px-4 py-2 border-b">{formatToZMW(account.amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
            }

<ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />
        </div>
    )

}
