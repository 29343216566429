import { Dashboard } from '@mui/icons-material';
import React, { useState } from 'react';
import { AiOutlineBell, AiOutlineUser } from 'react-icons/ai';
import { CiCalendar, CiSettings } from 'react-icons/ci';
import { FaBars, FaChartBar, FaCogs, FaHandshake, FaUser, FaBell, FaList, FaCog, FaCalendar, FaUsers, FaRegBell, FaSearch, FaUserCircle } from 'react-icons/fa';
import { FaListCheck, FaRegFileLines } from 'react-icons/fa6';
import { FiCalendar, FiSettings, FiUser } from 'react-icons/fi';
import { MdDashboard, MdOutlineDashboard, MdOutlineInbox } from 'react-icons/md';
import { PiToolboxThin } from 'react-icons/pi';
import { RiFileDamageLine } from 'react-icons/ri';
import { TbUsers } from 'react-icons/tb';
import { Outlet, useLocation, NavLink  } from 'react-router-dom';


export default function NavBar() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const currentUrl = location.pathname + location.search;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



    const facility = localStorage.getItem('Facility')

  const routes = [
    { path: '/', name: '', icon: <MdOutlineDashboard className="mr-2" size={25} /> },
    { path: '/matching', name: '', icon: <FiCalendar className="mr-2" size={25}  /> },
    { path: '/cohorts', name: '', icon: <TbUsers className="mr-2" size={25}  /> },
    { path: '/settings', name: '', icon: <MdOutlineInbox className="mr-2" size={25}  /> },
    { path: '/reports', name: '', icon: <FiSettings className="mr-2" size={25}  /> }
  ];
  
  return (
    <div className=" bg-gray-100">
      
     

      {/* Main Content */}
      <div className={`ml-auto w-full`} id="main-content">
        <div className="bg-white shadow p-4 mb-4">
          <header className="flex justify-between items-center h-[50px]">
            
                <div className='flex items-center text-2xl'>
                  <div className='rounded-full border-4 border-gray-200 mr-1'>
                  <img src="/logo1.png" className='w-[40px] p-1 m-1'  alt="" />
                  </div>
                <span className='font-semibold text-green-500'>Smart</span>
                <span className='font-semibold text-customBlue'>Care </span>
                <span className='font-bold text-customBlue ml-1'> Pro</span>
                </div>

                <div className='flex font-semibold'>
                  <button className='flex px-5 py-2 rounded-3xl m-2 bg-customBlue text-white items-center'> <FaSearch className='mr-1'/> Search Patient </button>

                  <select name="" id="" className='m-2 p-2'>
                    <option value=""> <RiFileDamageLine/> Services Queue </option>
                  </select>
                </div>

              <a href="#" className="relative text-gray-500 flex items-center">
               <span className='mr-2 font-semibold text-lg'></span> {facility}
                <FaUserCircle size={40} className='ml-2 text-blue-900'/>
              </a>

          
          </header>
        </div>

        <Outlet />
{/* 
        <div className="p-2 mt-4 fixed bottom-0 w-full">
          <footer className=" flex justify-center">
           Copyright &copy; {new Date().getFullYear()} Ministry of Health. All Rights Reserved.
          </footer>
        </div> */}
      </div>
    </div>
  );
}
