import axios from 'axios';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { rootUrl } from '../../../helpers';
import { FaSpinner, FaInfoCircle } from 'react-icons/fa';
import Select from 'react-select';

export default function EditForm({ responses, activityId, encounterId, user, closeModal, getResp }) {
  const [questions, setQuestions] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [calculatedResults, setCalculatedResults] = useState({});
  const [dsActive, setDsActive] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const iconRefs = useRef([]);

  useEffect(() => {
    fetchForm(activityId);
  }, [activityId]);

  const fetchForm = async (id) => {
    try {
      const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Service-forms/${id}`));
      if (data.status === 200) {
        setQuestions(data.data.result.fields);
        initializeFormValues(data.data.result.fields, responses);
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const initializeFormValues = (fields, responses) => {
    const initialValues = {};
    fields.forEach((field) => {
      const response = responses.find((resp) => resp.formId === field.formId);
      initialValues[field.name] = response?.resp || (field.type === 'checkbox' ? response?.resp.split(",") : '');
    });
    setFormValues(initialValues);
  };


  const [otherSelected, setOtherSelected] = useState(false);
    
  const [showOtherInputs, setShowOtherInputs] = useState({}); // State to track 'other' inputs for each field

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const fieldName = event.target.name;
    // Update the form values for the selected field
    handleChange(event);
    setShowOtherInputs(prevState => ({
        ...prevState,
        [fieldName]: selectedValue.toLowerCase().includes("other") || selectedValue.toLowerCase().includes("specify")
    }));
};

const handleOtherSelection = (selectedOptions) => {
  setOtherSelected(
      Array.isArray(selectedOptions) &&
      selectedOptions.some(option => 
          option?.value?.toLowerCase().includes("other") && 
          option?.label?.toLowerCase().includes("specify")
      )
  );
};

const isFieldDisabled = (name) => {
  if (name === 'FP.B.DE33' || name === 'FP.B.DE34' || name === 'FP.B.DE48' || 
      name === 'FP.C.DE1' ||
      name === 'FP.B.DE49'
  ) {
      return true; 
  }
  return false;
};

  const handleChange = async (e) => {
    const { type, name, value, checked, dataset } = e.target;
    let newValue = value;

    if (type === 'checkbox') {
      const currentValues = formValues[name] || [];
      newValue = checked
        ? [...currentValues, value]
        : currentValues.filter((v) => v !== value);
    }

    setFormValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    // if (dataset.formId) {
    //   try {
    //     const decisionData = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Form-Decision/${dataset.formId}`));
    //     handleDecisionSupport(decisionData.data, newValue, name);
    //   } catch (e) {
    //     toast.error(e?.message);
    //   }
    // }
  };

  // const handleDecisionSupport = (data, value, name) => {
  //   const applicableDecisions = data.filter((decision) => decision.condition.trim().toLowerCase() === value.trim().toLowerCase());
  //   if (applicableDecisions.length > 0) {
  //     const decision = applicableDecisions[0];
  //     displayDecision(decision.action, decision.colorCode);
  //   }
  // };

  const displayDecision = (action, colorCode) => {
    setCurrentField(<div>{action}</div>);
    setDsActive(true);
  };

  const handleMultiSelectChange = (selectedOptions, field) => {
    const values = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : selectedOptions
      ? selectedOptions.value
      : [];
    setFormValues((prev) => ({
      ...prev,
      [field.name]: values,
    }));
  };

  const shouldSkipField = (field) => {
    if (!field.skip) return false;

    const skipConditions = field.skip.split('|').map((cond) => cond.trim());
    return skipConditions.some((condition) => {
      const [target, operator, expectedValue] = condition.split(/(!?=)/);
      const actualValue = formValues[target]?.toString().trim() || '';
      return operator === '=' ? actualValue === expectedValue : actualValue !== expectedValue;
    });
  };

  const filteredFields = useMemo(() => {
    return questions.filter((field) => !shouldSkipField(field));
  }, [questions, formValues]);

  const groupByCategory = (fields) => {
    return fields.reduce((acc, field) => {
      (acc[field.cateory] = acc[field.cateory] || []).push(field);
      return acc;
    }, {});
  };

  const groupedFields = useMemo(() => groupByCategory(filteredFields), [filteredFields]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = Object.entries(formValues).map(([name, value]) => {
      const field = questions.find((q) => q.name === name);
      return {
        formId: field?.formId,
        encounterId,
        patient: user.patientId,
        addedBy: 'trice',
        response: Array.isArray(value) ? value.join(', ') : value,
      };
    });

    try {
      const resp = await axios.post(rootUrl(`/api/FamilyPlaning/Update-patient-response`), payload);
      if (resp.data.success) {
        toast.success('Update successful');
        getResp();
        closeModal();
      } else {
        toast.error('Update failed');
      }
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full">
      {dsActive && (
        <div className="fixed z-10 p-4 bg-gray-100 shadow-md">
          <div className="flex justify-between">
            <span>{currentField}</span>
            <button onClick={() => setDsActive(false)}>Close</button>
          </div>
        </div>
      )}

<form onSubmit={handleSubmit}>
                {Object.keys(groupedFields).length === 0 ? (
                    <p className="text-lg">No questions to display</p>
                ) : (
                    Object.entries(groupedFields).map(([cateory, fields]) => (
                        <div key={cateory} className="mb-6">
                            <h2 className="mt-3 text-xl font-semibold text-gray-800 mb-4 border-b border-gray-400 pb-1">{cateory}</h2>
                            <div className="grid grid-cols-2 gap-4">
                                {fields.map((field, index) => {
                                    if (field == null) return null;
                                    return (
                                        <div key={index} className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                                                {field.label.replace((`${field.name}.`), "")} {field.required.toLowerCase() === 'yes' || field.required === 'Conditional' ? '*' : ''}
                                                {field.decisionSupports.length > 0 ? (
                                                    <FaInfoCircle
                                                        ref={el => (iconRefs.current[index] = el)}
                                                        title="click to view annotations"
                                                        className="text-customBlue ml-2"
                                                    />
                                                ) : null}
                                            </label>
    
                                            {field.type === 'select' && (
                <div>
                    <select
                        name={field.name}
                        onChange={handleSelectChange} // Use the updated change handler
                        value={formValues[field.name] || ''}
                        data-form-id={field.formId}
                        className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                    >
                        <option value="">Select an option</option>
                        {field.options.map(option => (
                            <option key={option.optionId} value={option.optionType}>
                                {option.optionType}
                            </option>
                        ))}
                    </select>

                    {showOtherInputs[field.name] && (
                        <div className="mt-2">
                            <input
                                type="text"
                                name={`${field.name}_specify`}
                                onChange={handleChange}
                                value={formValues[`${field.name}_specify`] || ''}
                                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline mt-2"
                                placeholder="Please specify"
                            />
                        </div>
                    )}
                </div>
            )}
    
            {field.type.toLowerCase() === 'text' && (
                <input
                    type="text"
                    name={field.name}
                    onChange={handleChange}
                    value={formValues[field.name] || ''}
                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                    disabled={isFieldDisabled(field.name)}
                />
            )}
    
            {field.type === 'textarea' && (
                <textarea
                    name={field.name}
                    onChange={handleChange}
                    value={formValues[field.name] || ''}
                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                />
            )}
                                           
                                        {field.type === 'checkbox' && (
                                            field.options.length == -5?
                                        <div>
                                            {field.options
                                                .map(option => (
                                                    <div key={option.optionId} className="mb-2">
                                                        <label className="block text-gray-700 text-sm">
                                                            <input
                                                                type="checkbox"
                                                                name={field.name}
                                                                value={option.optionType}
                                                                onChange={(e) => handleChange(e, field.name, option.optionType)}
                                                                data-form-id={field.formId}
                                                                checked={formValues[field.name]?.includes(option.optionType)}
                                                                className="mr-2 leading-tight"
                                                            />
                                                            {option.optionType}
                                                        </label>
                                                        {option.optionType.toLowerCase().includes("other") && option.optionType.toLowerCase().includes("specify") 
                                                            ? formValues[field.name]?.includes(option.optionType) && (
                                                            <input
                                                                type="text"
                                                                name={`${field.name}_specify`}
                                                                value={formValues[`${field.name}_specify`] || ''}
                                                                onChange={handleChange}
                                                                placeholder="Please specify"
                                                                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 mt-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))
                                            }
                                        </div>:<>
                                         <Select
                                         options={[
                                           ...field.options
                                             .map(option => ({ value: option.optionType, label: option.optionType }))
                                         ]}
                                         isMulti={field.name !== 'FP.B.DE51' || field.name !== 'FP.B.DE29998' || field.name !== 'FP.B.DE30' }
                                         placeholder="Select options..."
                                         onChange={selectedOptions => {
                                           handleMultiSelectChange(selectedOptions, field);
                                           handleOtherSelection(selectedOptions);
                                         }}
                                         value={field.options
                                           .filter(option => formValues[field.name]?.includes(option.optionType))
                                           .map(option => ({ value: option.optionType, label: option.optionType }))
                                         }
                                       />
                                       
                                       {otherSelected && (
                                         <input
                                           type="text"
                                           placeholder="Please specify"
                                           name={`${field.name}_specify`}
                                           className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                           value={formValues[`${field.name}_specify`] || ''}
                                           onChange={handleChange}
                                         />
                                       )}
                                            </>
                                    )}

                                {field.type === 'radio' && (
                                    <div>
                                        {field.options
                                            .sort((a, b) => {
                                                if (a.optionType === "Yes") return -1;
                                                if (b.optionType === "Yes") return 1;
                                                return 0;
                                            })
                                            .map(option => (    
                                                <label key={option.optionId} className="block text-gray-700 text-sm mb-2">
                                                    <input
                                                        type="radio"
                                                        name={field.name}
                                                        value={option.optionType}
                                                        onChange={handleChange}
                                                        data-form-id={field.formId}
                                                        checked={formValues[field.name] === option.optionType}
                                                        className="mr-2 leading-tight"
                                                        required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                        disabled={isFieldDisabled(field.name)}
                                                    />
                                                    {option.optionType}
                                                </label>
                                            ))
                                        }
                                    </div>
                                )}

                                            {field.type === 'date' && (
                                                <input
                                                    type="date"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                />
                                            )}
    
                                            {field.type === 'time' && (
                                                <input
                                                    type="time"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                />
                                            )}

                                            {field.type === 'number' && (
                                                <input
                                                    type="number"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                    disabled={isFieldDisabled(field.name)}
                                                />
                                            )}
                                            {calculatedResults[field.name] && (
                                                    <p className="mt-2 text-sm text-blue-600 font-bold">
                                                        {calculatedResults[field.name]} 
                                                    </p>
                                                )}

                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))
                )}
    
                <hr className="my-2" />
                <div className="flex justify-center">
                    <button
                        disabled={loading}
                        type="submit"
                        className="w-[25%] mt-4 w-full bg-green-500 text-white px-4 py-2 rounded-md"
                    >
                        {loading ? <FaSpinner /> : 'Update'}
                    </button>
                </div>
            </form>
    </div>
  );
}
