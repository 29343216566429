import React, { useEffect, useState } from 'react'
import {
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Accordion,
  AccordionHeader, 
  AccordionBody 
} from "@material-tailwind/react";
import { FaPlus, FaTrash, FaUpload } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ModalDefault from '../../../../components/ModalDefault';
import { toast } from 'react-toastify';
import axios from 'axios';
import FormGenerator from '../../../../components/formGenerator';
import { rootUrl } from '../../../../helpers';
import Upload from '../upload';
import DynamicForm from './supportForm';
import InputForm from './inputForm';
import UploadSupport from './uploadSupport';


function Show({aspectId}){

    const [activities, setActivity] = useState([])
    const [forms, setForms] = useState([])
  
    const [open, setOpen] = useState(null);
  
    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})
  
     const fetchActivity = async () =>{
      try{
        const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-ServiceActivities/${aspectId}`))
        setActivity(data.data.serviceActivities)
      }catch(e){
        toast.error(e?.message)
      }
        
      }
  
      
     const fetchForm = async (id) =>{
      try{
        const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Service-Forms/${id}`))
        if(data.status === 200){
          
            var arr = []
           data.data.result.fields.map((res)=>{

            var opts = []


            res.options.map((ult)=>{
                opts.push({
                    value:ult.code,
                    name:ult.optionType
                })
            })

            

            var obj = {
                name:res.name,
                label:res.name+'. '+res.label,
                type:res.type,
                options:opts,
                id:res.formId
            }
  
  
            if(res.required === 'Yes'){
              obj['required'] = true
            }else if(res.required === 'Conditional'){
              obj['skipCondition'] = {
                conditionField: res.skip.split('=')[0],
                conditionValue: res.skip.split('=')[1],
              }
            }
            arr.push(
              obj
            )
          })
  
          setForms(arr)
  
        }else{
          toast.error("Something went wrong")
        }
        
      }catch(e){
        toast.error(e?.message)
      }
      }
  
      useEffect(()=>{
        fetchActivity()
      },[])
  
    const handleClose = () => {
      setModal({...modal, open: false })   
  }
  
    const handleOpen = (index) => {
      setOpen(open === index ? null : index);
    };
  
    const fields = [
      {type:'text', name:'activityName', label:'Name'},
  ]
  
  const submitSetting = async (data) => {
    try{
        const response = await axios.post(rootUrl(`/api/FamilyPlaning/Add-Service-Activities/${aspectId}`), [data])
            toast.success(response.data.message)
            setModal({...modal, open: false })  
    }catch(e){
        toast.error(e?.message)
    }
  }
  
    const addAct = () => {
      setModal({
        title:'Add Activity',
        open:true,
        size:'sm',
        body:<FormGenerator fields={fields} onSubmit={submitSetting} />
          })
    }
  
  var actId = 0
  
    const addForm = (id) => {
      actId = id
      setModal({
        title:'Add Form',
        open:true,
        size:'sm',
        body:<InputForm  activityId={id} closeModal={handleClose}/>
        })
    }
  
    const closeModal = () => {
      setModal({ ...modal, open: false });
    };
  
    const upload = (id) => {
      // actId = id
      setModal({
        title:'Upload',
        open:true,
        size:'sm',
        body:<Upload activityId={id} closeModal={closeModal}/>
          })
    }
    
  
    const addDec = async (id) =>{
      setModal({
        title:'Add Decision Support',
        open:true,
        size:'sm',
        body:<DynamicForm formId={id} />
          })
    }

    const upDec = async (id) =>{
      setModal({
        title:'Upload Decision Support',
        open:true,
        size:'sm',
        body:<UploadSupport formId={id} />
          })
    }

    
  
   
    const renderInput = (field) => {
      const { type, name, options } = field;
      switch (type) {
        case "textarea":
          return <textarea name={name} className="w-[75%] p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2" />;
        case "select":
          return (
            <select name={name} className="w-[75%] p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2">
              {options.map((option, index) => (
                <option key={index} value={option.code}>{option.name}</option>
              ))}
            </select>
          );
        case "radio":
          return options.map((option, index) => (
            <>
            <label key={index} className="flex items-center font-normal mt-2">
              <input type="radio" name={name} value={option.value} className="mr-2" />
              {option.name}
            </label>
            
            </>
          ));
        case "checkbox":
          return options.map((option, index) => (
            <>
            <label key={index} className="flex items-center font-normal mt-2">
              <input type="checkbox" name={name} value={option.value} className="mr-2" />
              {option.name}
            </label>
            
            </>
          ));
        default:
          return <input type={type.toLowerCase()} name={name} className="w-[75%] p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2" />;
      }
    };
  
      return (
          <div>
              <div className='flex justify-between'>
                  <div></div>
                  <button onClick={addAct} className='bg-blue-500 py-2 px-8 text-center text-white flex items-center'> <FaPlus/> Add Activity</button>
              </div>
        {activities && 
          activities.map((res, index)=>(
            <>
               <Accordion onClick={()=>fetchForm(res.activityId)} open={open === index} className="border-b-2 w-full">
          <div 
            onClick={() => handleOpen(index)} 
            className="cursor-pointer flex justify-between items-center w-full py-4 border-b border-b-blue-gray-100 text-blue-gray-700 text-xl font-semibold leading-snug transition-colors hover:text-blue-gray-900">
            <span>{res.activityName}</span>
            {open === index ? <FiChevronUp className="ml-2" /> : <FiChevronDown className="ml-2" />}
          </div>
          {open === index && (
            <AccordionBody className="transition-all duration-300">
              <button className='float-right bg-blue-500 py-2 px-8 text-center text-white flex items-center m-1' onClick={()=>addForm(res.activityId)}> <FaPlus/> </button>
              <button className='float-right bg-blue-500 py-2 px-8 text-center text-white flex items-center m-1' onClick={()=>upload(res.activityId)}> <FaUpload/> </button>
              <br/>
              {
              forms.length === 0?
              <>There are no forms for this activity</>:
              forms.map((res)=>(
                <div className="font-semibold mt-3">
                  
                  <div className='w-[75%] text-lg font-bold'>
                  <span>{res.label}</span>
                  <div className='flex items-center'>
                  <div className='w-full'>  {renderInput(res)}  </div>
                  <button onClick={()=>addDec(res.id)} className='m-2 float-right bg-green-500 py-1 px-4 text-center text-white flex items-center'><FaPlus/></button>
                  <button onClick={()=>upDec(res.id)} className='m-2 float-right bg-green-500 py-1 px-4 text-center text-white flex items-center'><FaUpload/></button>
                  <FaTrash className='text-red-500'/>
                  </div>
                  </div>
                  <hr />
                </div>
              ))
              }
            </AccordionBody>
          )}
        </Accordion>
            </>
          ))
        }
        <ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />
          </div>
      )
  }

  export default Show;