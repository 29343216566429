import React, {useState, useEffect} from 'react'
import Usercard from '../usercard';
import axios from 'axios';
import { rootUrl } from '../../../helpers';
import { toast } from 'react-toastify';
import Billing from '.';
import { FaAddressCard, FaFingerprint, FaSearch, FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate  } from 'react-router-dom';

export default function SearchUser() {

    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [bill, setBill] = useState(false);
    const [patientId, setPatientId] = useState(false);
    
    const fetchData = async () =>{
        try{
            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-patients`))
            setUsers(data.data)
        }catch(e){
            toast.error(e?.message)
        }
    }
    useEffect(()=>{
        fetchData()
    },[])

      const handleClick = (id) =>{
        setBill(true)
        setPatientId(id)
      }

  return (
    <div className="p-8">
    {!selectedUser ? (
      <Search users={users} onSelectUser={setSelectedUser} />
    ) : (
      <Usercard user={selectedUser} action='Bill' onClick={handleClick} />
    )}
    {bill?
    (
        <>
        <Billing patientId={patientId} user={selectedUser} />
        </>
    ):
    (
        <></>
    )}
    
  </div>
  )
}

const Search = ({ users, onSelectUser }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searched, setSearched] = useState([]);
  
    const filteredUsers = searchTerm
    ? users.filter(user =>
        user.patient.firstname.toLowerCase().includes(searchTerm.toLowerCase()) 
      )
    : [];

    const searchUser = () => {
    const filteredUsers = searchTerm
        ? users.filter(user =>
            user.patient.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.patient.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.patient.nrc.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.patient.nupin.toLowerCase().includes(searchTerm.toLowerCase()) 
          )
        : [];
        setSearched(filteredUsers)
    }

    const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const [activeTab, setActiveTab] = useState('tab1');
    const [activeTab2, setActiveTab2] = useState('tab1');

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    const handleTabClick2 = (tab) => {
      setActiveTab2(tab);
    };
    const navigate = useNavigate();

    const imageUrl = '/5.svg';
    return (
      <div className="p-4">
    
      <div className='flex justify-center shadow-2xl rounded-lg p-4 pb-10' style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className='text-center w-full'>
          <span className='m-5 text-blue-900 text-2xl font-bold'>Search Patient</span>
          <div className='flex items-center justify-center my-5'>
          <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab === 'tab1' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick('tab1')}
        >
          Biometrics
        </button>
        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab === 'tab2' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick('tab2')}
        >
          Details
        </button>
        
          </div>

          <div className="relative">
          {activeTab === 'tab1' && <div>
              <span className='text-lg font-bold pb-10'>
              Please select the finger and click on the icon to scan the fingerprint
              </span>
            <br />

            <div>

            <div className='flex items-center justify-center my-5'>
          <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab1' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab1')}
        >
          Left index
        </button>
        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab2' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab2')}
        >
          Left Thumb
        </button>

        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab3' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab3')}
        >
          Right Thumb
        </button>

        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab4' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab4')}
        >
          Right Index
        </button>
        
        
          </div>

            </div>
            <button className='absolute bottom-[-100px] left-1/2 transform -translate-x-1/2 bg-gray-500 rounded-full p-3'>
                <FaFingerprint size={50} color='white'/>
              </button>
         

            </div>}
          {activeTab === 'tab2' && <div className='w-full'>
            
              <input 
                  value={searchTerm}
                  onChange={handleInputChange}
                  className='rounded-3xl p-2 w-1/2 border focus:outline-none' type="text" />

              <button  onClick={searchUser}className='flex items-center px-6 text-white absolute bottom-[-70px] left-1/2 transform -translate-x-1/2 bg-green-500 rounded-full p-3'>
                <FaSearch/> Search
              </button>
            
            </div>}
          
          </div>
        </div>
      </div>

        <ul className="list-none p-0 mt-5 pt-10">
          {searched.map(user => (
            <li
              key={user.patient.id}
              className="p-5 text-blue-900 rounded-2xl m-3 border border-customBlue  cursor-pointer hover:bg-gray-100"
              onClick={() => onSelectUser(user.patient)}
            >
              <div className='flex justify-between items-center'>
              <span className=' font-bold text-lg flex items-center'> <FaUserCircle size={35}/> {user.patient.firstname} {user.patient.lastname}</span>
              <div className='flex text-center'>
                  <span className='m-2'>
                    <span className='font-bold flex items-center'> <FaAddressCard/> NRC</span>
                  {user.patient.nrc} 
                  </span>
                  <span className='m-2'>
                  <span className='font-bold flex items-center'><FaAddressCard/> NUPIN</span>
                  {user.patient.nupin} 
                  </span>
                </div>
              </div>
              <div className='flex justify-center'>
                {/* <button className='bg-customBlue px-6 text-white   rounded-full p-1 m-1' onClick={() => onSelectUser(user.patient)}>Bill</button> */}
                <button className='bg-green-500 px-6 text-white  rounded-full p-1 m-1' onClick={() => navigate(`/app/FP/${user.patient.patientId}`)}>Attend to patient</button>
              </div>
             
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
