import React, { useEffect, useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import { rootUrl } from '../../../../helpers';


const DynamicForm = ({formId}) => {

  const [formEntries, setFormEntries] = useState([
    { condition: '', output: '', action: '', annotation: '', secondCondition:'' }
  ]);


  const [decide, setDecide] = useState([])

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newFormEntries = [...formEntries];
    newFormEntries[index][name] = value;
    setFormEntries(newFormEntries);
  };


  const handleAddForm = () => {
    setFormEntries([...formEntries, { condition: '', output: '', action: '', annotation: '' }]);
  };


  const handleRemoveForm = (index) => {
    const newFormEntries = formEntries.filter((_, i) => i !== index);
    setFormEntries(newFormEntries);
  };


  const fetchData = async ()=>{
    try{
      const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Form-Decision/${formId}`))

      if(Array.isArray(data.data)){
        setDecide(data.data.map(res=>res.decisiondata))
      }

      
    }catch(e){
      toast.error(e?.message)
    }
  }

  useEffect(()=>{
    fetchData()
  },[])


  const handleSubmit = async (event) => {
    event.preventDefault();
    try{

      const response = await axios.post(rootUrl(`/api/FamilyPlaning/Decision-support/${formId}`),formEntries)
      toast.success(response.data.message)
      setFormEntries([{ condition: '', output: '', action: '', annotation: '' }]);
      fetchData()
    }catch(e){
      toast.error(e?.message)
    }

    // console.log('Submitted data:', formEntries);

  };

  return (
    <div>

<div className="container mx-auto my-4">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className='bg-blue-500 text-white'>
            <th className="py-2 px-4 border-b text-left">Condition</th>
            <th className="py-2 px-4 border-b text-left">2nd condition</th>
            <th className="py-2 px-4 border-b text-left">Output</th>
            <th className="py-2 px-4 border-b text-left">Action</th>
            <th className="py-2 px-4 border-b text-left">Annotation</th>
          </tr>
        </thead>
        <tbody>
          {decide.map((item, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border-b">{item.condition}</td>
              <td className="py-2 px-4 border-b">{item.secondCondition}</td>
              <td className="py-2 px-4 border-b">{item.output}</td>
              <td className="py-2 px-4 border-b">{item.action}</td>
              <td className="py-2 px-4 border-b">{item.annotation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <form onSubmit={handleSubmit}>
      {formEntries.map((entry, index) => (
        <div key={index} className="flex items-center">
          <input
            type="text"
            name="condition"
            value={entry.condition}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Condition"
            className="p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2"
          />
        <input
            type="text"
            name="secondCondition"
            value={entry.secondCondition}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="2nd condition"
            className="p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2"
          />
          <input
            type="text"
            name="output"
            value={entry.output}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Output"
            className="p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2"
          />
          <input
            type="text"
            name="action"
            value={entry.action}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Action"
            className="p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2"
          />
          <input
            type="text"
            name="annotation"
            value={entry.annotation}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Annotation"
            className="p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500 m-2"
          />
          <button type="button" onClick={() => handleRemoveForm(index)} className="bg-red-500 py-2 px-8 text-center text-white flex items-center">
            <FaMinus/>
          </button>
        </div>
      ))}
        <div className='flex justify-center'>
      <button type="button" onClick={handleAddForm} className="bg-blue-500 mb-2 py-2 px-8 text-center text-white flex items-center">
          <FaPlus/>
      </button>
      </div>
      <hr className='my-3'/>
      <div className='flex justify-center'>
      <button type="submit" className="bg-green-500 py-2 px-8 text-center text-white flex items-center">
        Submit
      </button>
      </div>
     
    </form>
    </div>
  );
};

export default DynamicForm;