import React, { useEffect, useState } from 'react'
import {
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Accordion,
  AccordionHeader, 
  AccordionBody 
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import axios from 'axios';
import { rootUrl } from '../../../helpers';
import Show from './components/show';

export default function Activity() {

    const [activeTab, setActiveTab] = useState('Registration');
    const [aspects, setAspects] = useState([])
    const [data, setData] = useState([])

    const getAspect = async () =>{
      try{
        const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Aspects`))
        setAspects(data.data.serviceActivities)
        setData(
          data.data.serviceActivities.map((res) => ({
            label: res.aspectName,
            value: res.aspectName,
            icon: '',
            desc: <Show aspectId={res.aspectId} />,
          }))
        );
      }catch(e){
        toast.error(e?.message)
      }
      } 

      useEffect(()=>{
        getAspect()
    },[])

  return (
        <div className="p-5">
          <div className='bg-white p-4'>
        <Tabs value="Registration">
            <TabsHeader className="bg-gray-100 rounded-lg border shadow">
                {data.map(({ label, value, icon }) => (
                <Tab    key={value}
                        value={value}
                        onClick={() => setActiveTab(value)}
                        className={`flex items-center gap-2 text-blue-gray-700 hover:bg-blue-gray-50 active:bg-blue-gray-100 rounded-lg p-2 transition
                            ${
                                activeTab === value
                                    ? "bg-white text-blue-gray-700 rounded"
                                    : "text-blue-gray-700 hover:bg-blue-gray-200 active:bg-blue-gray-300"
                            }
                        `}
             >
                    <div className="flex items-center gap-2">
                    <span className="font-medium">{label}</span>
                    </div>
                </Tab>
                ))}
            </TabsHeader>
            <TabsBody>
                {data && data.map((res) => (
                <TabPanel key={res.value} value={res.value} className="p-4">
                     <p className="text-blue-gray-700">{res.desc}</p>
                </TabPanel>
                ))}
            </TabsBody>
    </Tabs>


    </div>
    </div>
  )
}




