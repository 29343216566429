import React from 'react'
import FacilitySearch from './encounter/facility';

import {  Link } from 'react-router-dom';
import { Height } from '@mui/icons-material';

export default function LandingPage() {
  
  const divStyle = {
    backgroundImage: 'linear-gradient(rgba(11, 127, 171, 0.5), rgba(11, 127, 171, 0.5)), url(/bg.jpg)', // Path to your image
    backgroundSize: 'cover',  // Cover the entire div
    backgroundPosition: 'center',  // Center the image
    height: '100vh',
    
};

  return (
    <div style={divStyle} className="flex justify-center items-center">
      <div className="bg-white border shadow-2xl p-5 relative w-1/3 rounded-3xl text-center">
        <div className="flex justify-center relative">
          <div className="absolute -top-10 text-center">
            <div className="rounded-full border-4 border-gray-200 p-1 w-[80px] h-[80px] flex justify-center items-center bg-white shadow-md">
              <img src="/logo1.png" className="w-[40px] p-1 m-1" alt="Smart Care Logo" />
            </div>
          </div>
        </div>
        <div className="mt-12"> 
          <div className="flex items-center justify-center text-4xl my-2">
            <span className="font-semibold text-green-500">Smart</span>
            <span className="font-semibold text-customBlue">Care</span>
            <span className="font-bold text-customBlue ml-1">Pro</span>
          </div>
          <p className='text-sm my-2'>A Ministry of Health SmartCare System</p>
          <span className='text-blue-900 text-bold text-2xl my-2'>Select Facility</span>
          <FacilitySearch />
        </div>
      </div>
    </div>
  );

}
