import React, { useEffect, useState } from 'react';
import { AiOutlineBell, AiOutlineUser } from 'react-icons/ai';
import { FaBars, FaChartBar, FaCogs, FaHandshake, FaUser, FaBell, FaList, FaCog, FaUsers, FaUpload, FaMoneyBill, FaSignOutAlt } from 'react-icons/fa';
import { FaListCheck } from 'react-icons/fa6';
import { MdDashboard } from 'react-icons/md';
import { Outlet, useLocation, NavLink  } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { rootUrl } from '../helpers';


export default function AdminLayout() {



    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();
    const currentUrl = location.pathname + location.search;
  
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    const routes = [
      { path: '/admin', name: 'Dashboard', icon: <MdDashboard className="mr-2" /> },
      { path: '/admin/activity', name: 'Questionnaire', icon: <FaUsers className="mr-2" /> },
      { path: '/admin/billing', name: 'Billing', icon: <FaMoneyBill className="mr-2" /> },
      { path: '/admin/reports', name: 'Reports', icon: <FaChartBar className="mr-2" />  },
    ];

    const signOut = () => {
      window.href="/"
    }

return (
    <div className="flex h-screen bg-gray-100 h-full">
      {/* Sidebar */}
      <div className={`w-1/5 text-lg bg-white text-black p-4 m-4 rounded-lg shadow-lg fixed h-[95vh] transform  lg:translate-x-0 transition-transform duration-300 ease-in-out`}>
        <div className="mb-4 text-center font-bold">
          <img src="/logo1.png" alt="Logo" className="mx-auto w-1/3" />
          
        </div>

        <hr className='mb-5' />
        <ul className="space-y-2">
          {routes.map((route) => (
            <li
              key={route.path}
              className={`border-blue-500 rounded-lg cursor-pointer py-2 px-4 flex items-center ${currentUrl === route.path ? 'border' : ''}`}
            >
              <NavLink to={route.path} className="flex items-center w-full">
                {route.icon} {route.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className={`p-6 ml-auto w-full lg:w-4/5`} id="main-content">
        <div className="bg-white rounded-lg shadow p-4 mb-4">
          <header className="flex justify-between items-center">
            <h1 className="text-xl font-semibold flex items-center">
              {/* <button className="mr-2 text-gray-800 cursor-pointer lg:hidden" onClick={toggleSidebar}>
                <FaBars/>
              </button> */}
              <span>Admin</span>
            </h1>
           
            <div className="flex items-center space-x-4">
              <a href="#" className="relative text-gray-800 flex items-center">
                <AiOutlineBell size={30} className='m-2'/>
                <AiOutlineUser size={30} className='m-2'/>
                
                <FaSignOutAlt onClick={signOut} title='logout' className='text-2xl'/>
              </a>
              <a className="text-gray-800 flex items-center">
                <i className="fa fa-user fa-2x mr-1"></i>
              </a>
            </div>
          </header>
        </div>

        <Outlet />

        <div className="bg-white rounded-lg shadow p-2 mt-4">
          <footer className="text-center">
            &copy; 2024. All rights reserved.
          </footer>
        </div>
      </div>
    </div>
  );

}